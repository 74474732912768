<template>
  <div class="p-4 bg-white shadow rounded">
    <a-skeleton v-if="loading" :loading="loading" active> </a-skeleton>
    <iframe
      v-show="!loading"
      src="https://legalnaija.com/blawg"
      width="100%"
      class="h-screen"
      @load="doneLoading"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: 'LawyerBlawg',
  layout: 'lawyerDashboard',
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    doneLoading() {
      console.log('done_loading')
      this.loading = false
    },
  },
}
</script>
